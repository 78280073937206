<template>
  <div>
    <div v-if='loginPanelState' class="login-pnl"  id='pnlLogin'>
      <div class="logo"></div>
      <div class="logo2"></div>
      <!-- <h4 style="margin-top: 200px; text-align: center" >Kısa bir süre için bakım çalışması yapılmaktadır</h4> -->
      <input type="text" class='txtCode' id='txtCode' v-model='lwoCode'>
      <div class='orngButtonLogin' id='btnActivate' @click.prevent="checkCode()">Login</div>
    </div>
    <!-- hidden -->
    <div class="content" hidden id='pnlSelection_lvl1'>
      <div class="game1" onclick="playgame()">
        <div class="orngButton" onclick="playgame()">Games</div>
      </div>
      <div class="video1" onclick="openvideos()">
        <div class="orngButton" onclick="openvideos()">Videos</div>
      </div>
      <div class="stories1">
        <div class="orngButton" onclick="openmp3()">Songs and Stories</div>
      </div>
    </div>
    <!-- hidden -->
    <div class="content" hidden id='pnlSelection_lvl2'>
      <div class="game2" onclick="playgame()">
        <div class="orngButton" onclick="playgame()">Games</div>
      </div>
      <div class="stories2">
        <div class="orngButton" onclick="openmp3()">Songs and Stories</div>
      </div>
      <div class="video2" onclick="openvideos()">
        <div class="orngButton" onclick="openvideos()">Videos</div>
      </div>
    </div>
    <!-- hidden -->
    <div class="content" hidden id='pnlSelection_lvl3'>
      <div class="game3" onclick="playgame()">
        <div class="orngButton" onclick="playgame()">Games</div>
      </div>
      <div class="stories3">
        <div class="orngButton" onclick="openmp3()">Songs and Stories</div>
      </div>
      <div class="video3" onclick="openvideos()">
        <div class="orngButton" onclick="openvideos()">Videos</div>
      </div>
    </div>
    <!-- hidden -->
    <div class="mp3ler" hidden id='pnlMp3s'>
      <h1 style='top: 0; left: 0; color: #bfd540;text-shadow: 0 0 0.2em #000, 0 0 0.2em #000, 0 0 0.2em #000;'>Learn With Ollie Sounds</h1>
      <div id='vidContainers'></div>
      <div class="btnBack" onclick='backMenu()'>
        Back
      </div>
    </div>
    <!-- hidden -->
    <div class="content" hidden id='pnlVideos'>
      <h1 style='top: 0; left: 0;'>Videos</h1>
      <div id='vidContainer'></div>
      <div class="btnBack" onclick='back()'>
        Back
      </div>
    </div>
    <div hidden id='gameFrame' class="frame">
      <iframe src="" frameborder="0"></iframe>
      <div class="btnSaveAndExit" onclick='saveAndExit()'>Save & Exit</div>
    </div>
    <div hidden id='videoFrame' class="frame">
      <video controls src="" frameborder="0"></video>
      <div class="btnSaveAndExit" onclick='Exit()'>Exit</div>
    </div>
    <div hidden id='pnlErr' class="frame">
      <div class="pnlErr">
        <p id='errMsg' class='errMsg'>Geçersiz Kod</p>
        <div class="btnOkay" onclick='Okay()'>Okay</div>      
      </div>
    </div>
    <!-- <div class="bgWhite" id='pnlRotation'>
      <div class="rotation" ></div>
    </div> -->
  </div>
</template>

<script>
import {store} from "../store/index";
export default {
  name: 'LoginPage',
  data(){
    return {
      lwoCode: null,
      loginPanelState : true
    }
  },
  methods :{
    checkCode (value){
       const lwoCode = value != null ? value : this.lwoCode
       const codeInOne =  store.state.lwo1.find((element) => element == lwoCode );
       const codeInTwo =  this.$store.state.lwo2.find((element) => element == lwoCode );
       const codeInThree =  this.$store.state.lwo3.find((element) => element == lwoCode );
      //  console.log('sssss',codeInOne, codeInTwo, codeInThree)

       const lvl = codeInOne ? 1 :(codeInTwo ? 2 : (codeInThree ? 3 : null))

       if (lvl){
        const content = {}
        content.gamePath = `https://storage.googleapis.com/lwocdn/LWO/${lvl}/Games/index.html`;
        // content.gamePath = `../LWO/${lvl}/Games/index.html`;
        const videos = lvl == 1 ? store.state.lwo1Videos : lvl == 2 ? store.state.lwo2Videos : lvl == 3 ? store.state.lwo3Videos : null
        const mp3s = lvl == 1 ? store.state.lwo1Sounds : lvl == 2 ? store.state.lwo2Sounds : lvl == 3 ? store.state.lwo3Sounds : null
        
        const _videos = [];        
        for (let i = 0; i < videos.length; i += 1) {
          let element = videos[i];
          _videos.push(`https://storage.googleapis.com/lwocdn/LWO/${lvl}/videos/${element}`);
          // _videos.push(`..LWO/${lvl}/videos/${element}`);
        }
        content.videos = _videos;
        
        const _mp3s = []
        for (let i = 0; i < mp3s.length; i += 1) {
          let element = mp3s[i];
          _mp3s.push(`https://storage.googleapis.com/lwocdn/LWO/${lvl}/sounds/${element}`);
          // _mp3s.push(`..LWO/${lvl}/sounds/${element}`);
        }
        content.mp3s = _mp3s;

        window.login(1, lvl, this.lwoCode, content, '')
       } else {
        window.login(0, '', '', '', 'Code is not valid!')
      }
      }
    },

    setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  },

  async mounted() {
    const query = window.location.search;
    if (query) {
      const splitQuery = window.atob(query.substr(1));
      // this.lwoCode = splitQuery
      this.checkCode(splitQuery)
    }

  },
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
