var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loginPanelState)?_c('div',{staticClass:"login-pnl",attrs:{"id":"pnlLogin"}},[_c('div',{staticClass:"logo"}),_c('div',{staticClass:"logo2"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lwoCode),expression:"lwoCode"}],staticClass:"txtCode",attrs:{"type":"text","id":"txtCode"},domProps:{"value":(_vm.lwoCode)},on:{"input":function($event){if($event.target.composing)return;_vm.lwoCode=$event.target.value}}}),_c('div',{staticClass:"orngButtonLogin",attrs:{"id":"btnActivate"},on:{"click":function($event){$event.preventDefault();return _vm.checkCode()}}},[_vm._v("Login")])]):_vm._e(),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content",attrs:{"hidden":"","id":"pnlSelection_lvl1"}},[_c('div',{staticClass:"game1",attrs:{"onclick":"playgame()"}},[_c('div',{staticClass:"orngButton",attrs:{"onclick":"playgame()"}},[_vm._v("Games")])]),_c('div',{staticClass:"video1",attrs:{"onclick":"openvideos()"}},[_c('div',{staticClass:"orngButton",attrs:{"onclick":"openvideos()"}},[_vm._v("Videos")])]),_c('div',{staticClass:"stories1"},[_c('div',{staticClass:"orngButton",attrs:{"onclick":"openmp3()"}},[_vm._v("Songs and Stories")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content",attrs:{"hidden":"","id":"pnlSelection_lvl2"}},[_c('div',{staticClass:"game2",attrs:{"onclick":"playgame()"}},[_c('div',{staticClass:"orngButton",attrs:{"onclick":"playgame()"}},[_vm._v("Games")])]),_c('div',{staticClass:"stories2"},[_c('div',{staticClass:"orngButton",attrs:{"onclick":"openmp3()"}},[_vm._v("Songs and Stories")])]),_c('div',{staticClass:"video2",attrs:{"onclick":"openvideos()"}},[_c('div',{staticClass:"orngButton",attrs:{"onclick":"openvideos()"}},[_vm._v("Videos")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content",attrs:{"hidden":"","id":"pnlSelection_lvl3"}},[_c('div',{staticClass:"game3",attrs:{"onclick":"playgame()"}},[_c('div',{staticClass:"orngButton",attrs:{"onclick":"playgame()"}},[_vm._v("Games")])]),_c('div',{staticClass:"stories3"},[_c('div',{staticClass:"orngButton",attrs:{"onclick":"openmp3()"}},[_vm._v("Songs and Stories")])]),_c('div',{staticClass:"video3",attrs:{"onclick":"openvideos()"}},[_c('div',{staticClass:"orngButton",attrs:{"onclick":"openvideos()"}},[_vm._v("Videos")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mp3ler",attrs:{"hidden":"","id":"pnlMp3s"}},[_c('h1',{staticStyle:{"top":"0","left":"0","color":"#bfd540","text-shadow":"0 0 0.2em #000, 0 0 0.2em #000, 0 0 0.2em #000"}},[_vm._v("Learn With Ollie Sounds")]),_c('div',{attrs:{"id":"vidContainers"}}),_c('div',{staticClass:"btnBack",attrs:{"onclick":"backMenu()"}},[_vm._v(" Back ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content",attrs:{"hidden":"","id":"pnlVideos"}},[_c('h1',{staticStyle:{"top":"0","left":"0"}},[_vm._v("Videos")]),_c('div',{attrs:{"id":"vidContainer"}}),_c('div',{staticClass:"btnBack",attrs:{"onclick":"back()"}},[_vm._v(" Back ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame",attrs:{"hidden":"","id":"gameFrame"}},[_c('iframe',{attrs:{"src":"","frameborder":"0"}}),_c('div',{staticClass:"btnSaveAndExit",attrs:{"onclick":"saveAndExit()"}},[_vm._v("Save & Exit")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame",attrs:{"hidden":"","id":"videoFrame"}},[_c('video',{attrs:{"controls":"","src":"","frameborder":"0"}}),_c('div',{staticClass:"btnSaveAndExit",attrs:{"onclick":"Exit()"}},[_vm._v("Exit")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame",attrs:{"hidden":"","id":"pnlErr"}},[_c('div',{staticClass:"pnlErr"},[_c('p',{staticClass:"errMsg",attrs:{"id":"errMsg"}},[_vm._v("Geçersiz Kod")]),_c('div',{staticClass:"btnOkay",attrs:{"onclick":"Okay()"}},[_vm._v("Okay")])])])
}]

export { render, staticRenderFns }